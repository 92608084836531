body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  background-color: #e0f7fa;
}

.MuiAppBar-root {
  background-color: #2E3B55;
}

.MuiContainer-root {
  padding: 20px;
}

.MuiPaper-root {
  padding: 20px;
  background-color: #F5F5F5;
}

.MuiTableCell-root {
  padding: 12px;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}

.MuiTableHead-root {
  background-color: #2E3B55;
}

.MuiTableCell-head {
  color: white;
  font-weight: bold;
}

.MuiTypography-h4 {
  margin-bottom: 20px;
  font-size: 28px;
  font-weight: bold;
}

.MuiTypography-h6 {
  font-weight: bold;
}

@media (min-width: 960px) {
  .MuiTableContainer-root {
    max-width: 100%;
  }
}
